<template>
    <div>
        <input type="file" id="ifcpsinput" @change="upload" />
        <v-btn @click="click" color="accent" text>
            <v-icon left> mdi-upload </v-icon> UPLOAD
        </v-btn>
    </div>
</template>

<style scoped>
input {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
}
</style>

<script>
export default {
    methods: {
        click() {
            document.getElementById("ifcpsinput").click();
        },
        upload() {
            var file_to_read = document.getElementById("ifcpsinput").files[0];
            if (!file_to_read) return;
            var fileread = new FileReader();
            fileread.onload = this.loaded;
            fileread.readAsText(file_to_read);
        },
        loaded(e) {
            var content = e.target.result;
            var ifcps = JSON.parse(content);
            this.$emit("uploaded", ifcps);
        },
    },
    mounted() {},
};
</script>