<template>
    <div class="d-flex flex-wrap">
        <v-text-field
            class="mx-1"
            dense
            outlined
            label="zone"
            type="number"
            min="-20"
            max="20"
            v-for="(z, i) in zones"
            v-model="zones[i]"
            :key="i"
            append-icon="mdi-close"
            @click:append="remove(i)"
        >
        </v-text-field>
        <span v-if="zones.length == 0" class="caption"> No defined zones </span>
        <v-btn icon @click="add">
            <v-icon> mdi-plus </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ zones: [1] }),
    watch: {
        value: {
            handler: function (v) {
                this.zones = v;
            },
            immediate: true,
        },
        zones(zones) {
            this.$emit("input", zones);
        },
    },
    methods: {
        add() {
            this.zones.push(0);
        },
        remove(i) {
            this.zones.splice(i, 1);
        },
    },
};
</script>