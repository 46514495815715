<template>
    <v-btn icon @click="download">
        <a :id="name" />
        <v-icon> mdi-download </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: ["json", "prefix"],
    computed: {
        name() {
            return this.json.name;
        },
    },
    methods: {
        download() {
            let form = { ...this.json };
            delete form.uuid;
            delete form.id;
            delete form.company;
            if (form.read_only) form.read_only = false;
            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(form));
            var dlAnchorElem = document.getElementById(this.name);
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute(
                "download",
                `${this.prefix}_${this.json.name}.json`
            );
            dlAnchorElem.click();
        },
    },
};
</script>